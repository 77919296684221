import '@forge/ant-theme/causamatics.min.css';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router';
import './app.css';
import { appRoutesConfig } from './app.routes';
import { history } from './common';
import { RootStore } from './root.store';

@observer
export class App extends Component {
    private rootStore: RootStore = new RootStore();

    public render(): React.ReactNode {
        return (
            <Provider {...this.rootStore}>
                <Router history={history}>
                    <Switch>
                        {Object.keys(appRoutesConfig).map((key) => {
                            const routeConfig = { ...appRoutesConfig[key] };
                            const { component } = routeConfig;
                            return (
                                <Route {...routeConfig} key={key} component={component} />
                            );
                        })}
                    </Switch>
                </Router>
            </Provider>
        );
    }
}
